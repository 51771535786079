export const softSkills = [
  'Problem Solving',
  'Creativity',
  'Leadership',
  'Organization',
  'Critical Thinking',
  'Public Speaking',
  'Team Work',
  'Interpersonal communication',
  'Intrapersonal communcation',
  'Innovation',
  'Work ethic',
  'Trustworthiness',
  'Flexibility',
  'Openness to criticism',
  'Decision-making',
  'Responsiveness',
  'Curiosity',
  'Professionalism',
  'Attention to detail',
  'Analytical thinking',
  'Conflict management',
  'Fast learner',
  'Database programming',
  'Organizational skills',
  'Data structures and algorithms',
  'Time management',
];

export const hardSkills = [
  'Full-stack Development',
  'Back-end Development',
  'Front-end Development',
  'Software Development',
  'Cloud Development',
  'Mobile Development',
  'Embedded Systems Development',
  'Game Development',
  'Database Development',
  'PHP Development',
  'Blockchain Development',
  'DevOps Development',
  'AR/VR Development',
  'UI/UX Design',
  'Graphic Design',
  'Web Design',
  'Technical Design',
  'Video Game Design',
  'Product Design',
  'Visual Design',
  'Motion Graphic Design',
  'Packaging Design',
  'Interior Design',
  'Motion graphics Design',
  'Industrial Design',
  'Mentorship',
  'Video Editing',
  'Management',
  'Project Management',
  'Graphic Artist',
  'Instructor',
  'Sound Effects',
  'Marketing',
  'Cyber Security',
  'Web editor',
  'Digital Strategy',
  'Technical Writing',
  'IT',
];

export const proficiencies = [
  'C++',
  'CSS',
  'C#',
  'Operating Systems',
  'Linux',
  'Windows',
  'Full-stack development',
  'Java',
  'Javascript',
  'SQL',
  'Debugging',
  'Visual Studio',
  'Visual Studio Code',
  'Xcode',
  'PyCharm',
  'Go',
  'Mircosoft Azure',
  'API',
  'GIthub',
  'Git',
  'Ruby',
  'PHP',
  'C',
  'Kotlin',
  'Ruby',
  'Unity',
  'Sketch',
  'Figma',
  'Adobe',
  'Adobe PhotoShop',
  'Canva',
  'Fotor',
  'GIMP',
  'Adobe XD',
  'Axure',
  'Adobe Illustrator',
  'Desygner',
  'Coolers',
  'Pixlr',
  'Visme',
  'Webflow',
  'Blender',
  'Procreate',
  'Autodesk Maya',
  'Aseprite',
];
