export const routes = {
  DEFAULT: '/',
  LOGIN: '/login',
  SIGNUP: '/signup',
  FORGOTPASSWORD: '/forgotPassword',
  RESETPASSWORD: '/resetPassword/:token',
  HOME: '/discover',
  MEET: '/meet',
  MESSAGES: '/messages',
  MYPROJECTS: '/myProjects',
  PROFILE: '/profile',
  NEWPROFILE: '/newProfile',
  PROJECT: '/project',
  NEWPROJECT: '/newProject',
  PROJECTPOST: '/projectPost',
  CREATEPROJECT: '/createProject',
  MESSAGEHISTORY: '/messageHistory',
  DISCOVERANDMEET: '/discoverAndMeet',
  SETTINGS: '/settings',
  CREDITS: '/credits',
  NOTFOUND: '/notFound',
  ACCOUNTACTIVATE: '/activation/:token',
};
